import * as React from 'react';
import Home from './home';
import { useIntl } from 'react-intl';

const IndexPage = () => {
  return <Home />;
};

export default IndexPage;


export const Head = () => {
  const intl = useIntl();
  return (
    <>
      <title>{intl.formatMessage({ id: 'seoTitle' })}</title>
      <meta name="description" content={intl.formatMessage({ id: 'seoDescription' })} />
      <meta name="keywords" content={intl.formatMessage({ id: 'seoKeywords' })} />
      <meta name="robots" content={intl.formatMessage({ id: 'seoRobots' })} />
      <link rel="canonical" href={intl.formatMessage({ id: 'seoCanonical' })} />
      <meta property="og:title" content={intl.formatMessage({ id: 'seoTitle' })} />
      <meta property="og:description" content={intl.formatMessage({ id: 'seoDescription' })} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={intl.formatMessage({ id: 'seoCanonical' })} />
      <meta property="og:image" content={intl.formatMessage({ id: 'ogImage' })} />
      <meta name="twitter:card" content={intl.formatMessage({ id: 'twitterCard' })} />
      <meta name="twitter:title" content={intl.formatMessage({ id: 'seoTitle' })} />
      <meta name="twitter:description" content={intl.formatMessage({ id: 'seoDescription' })} />
      <meta name="twitter:image" content={intl.formatMessage({ id: 'twitterImage' })} />
    </>
  );
};
